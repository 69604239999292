import React from 'react'
import Dress from '../Components/Dresses/Dresses'

const Dresses = () => {
  return (
    <div>
        <Dress/>
    </div>
  )
}

export default Dresses