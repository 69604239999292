import React from 'react'
import Jewelery from '../Components/Jewellery/Jewellery'

const Jewellery = () => {
  return (
    <div>
        <Jewelery/>
    </div>
  )
}

export default Jewellery