import React from 'react'
import Makeover from '../Components/Makeover/Makeover'

const Makeovers = () => {
  return (
    <div>
        <Makeover/>
    </div>
  )
}

export default Makeovers